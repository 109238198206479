import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import image from '../assets/MasterCraftwhite.png';
//import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
      <Container fluid className="logo">
        <a href="https://www.mastercraftbodyworks.co.uk">
          <img src={image} alt="Mastercraft Bodyworks" className="logo ml-3"/>
        </a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#" onClick={e => {
              let hero = document.getElementById("copySection");
              e.preventDefault();
              hero && hero.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }}>About us</Nav.Link>
            <Nav.Link href="#" onClick={e => {
              let hero = document.getElementById("services");
              e.preventDefault();
              hero && hero.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }}>Our services</Nav.Link>
            <Nav.Link href="#" onClick={e => {
              let hero = document.getElementById("contactUs");
              e.preventDefault();
              hero && hero.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }}>Contact us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;