import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel, Card, Stack, Button, Row, Col, Container } from "react-bootstrap";
import image1 from '../assets/repair2.jpg';
import image2 from '../assets/minorScratches.jpg'
import image3 from '../assets/restoration.jpg'
import image4 from '../assets/detailing.jpg'
import image5 from '../assets/wheels.jpg'
import image6 from '../assets/welding.jpg'

function CarouselMod() {

  return (
    <Container className="mt-4">
    <Row>
    <Col>
    <h1 className="servicesTitle mb-4">Our services</h1>
    </Col>
    </Row>
      <Row className="desktopCarousel">
        <Col sm className="p-3">
          <Card className="bg-dark text-white" style={{ width: '100%' }}>
            <Card.Img src={image1} width="100%" height="auto" alt="Accident Repair" />
              <Card.ImgOverlay>
                <Card.Title>Accident Repair</Card.Title>        
              </Card.ImgOverlay>
          </Card>  
        </Col>
        <Col sm className="p-3"> 
          <Card className="bg-dark text-white" style={{ width: '100%' }}>
            <Card.Img src={image2} width="100%" height="auto" alt="Minor Scuffs & Scratches" />
              <Card.ImgOverlay>
                <Card.Title>Minor Scuffs & Scratches</Card.Title>        
              </Card.ImgOverlay>
          </Card>  
        </Col>
        <Col sm className="p-3">
          <Card className="bg-dark text-white" style={{ width: '100%' }}>
            <Card.Img src={image3} width="100%" height="auto" alt="Vehicle Restoration" />
              <Card.ImgOverlay>
                <Card.Title>Vehicle Restoration</Card.Title>        
              </Card.ImgOverlay>
          </Card>  
        </Col>
      </Row>
      <Row className="desktopCarousel">
        <Col sm className="p-3">
          <Card className="bg-dark text-white" style={{ width: '100%' }}>
            <Card.Img src={image4} width="100%" height="auto" alt="Detailing & Ceramic Coating" />
              <Card.ImgOverlay>
                <Card.Title>Detailing & Ceramic Coating</Card.Title>        
              </Card.ImgOverlay>
          </Card>  
        </Col>
        <Col sm className="p-3"> 
          <Card className="bg-dark text-white" style={{ width: '100%' }}>
            <Card.Img src={image5} width="100%" height="auto" alt="Wheel Refurbishment" />
              <Card.ImgOverlay>
                <Card.Title>Wheel Refurbishment</Card.Title>        
              </Card.ImgOverlay>
          </Card>  
        </Col>
        <Col sm className="p-3">
          <Card className="bg-dark text-white" style={{ width: '100%' }}>
            <Card.Img src={image6} width="100%" height="auto" alt="Welding & Fabrication" />
              <Card.ImgOverlay>
                <Card.Title>Welding & Fabrication</Card.Title>        
              </Card.ImgOverlay>
          </Card>  
        </Col>
      </Row>
      <Row className="mobileCarousel">
       <Carousel>
        <Carousel.Item>
          <img alt="Accident Repair" src={image1} />
          <Carousel.Caption>
            <p>Accident Repair</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Minor Scuffs & Scratches" src={image2} />
          <Carousel.Caption>
            <p>Minor Scuffs & Scratches</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Vehicle Restoration" src={image3} />
          <Carousel.Caption>
            <p>Vehicle Restoration</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Detailing & Ceramic Coating" src={image4} />
          <Carousel.Caption>
            <p>Detailing & Ceramic Coating</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Minor Scuffs & Scratches" src={image5} />
          <Carousel.Caption>
            <p>Wheel Refurbishment</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Welding & Fabrication" src={image6} />
          <Carousel.Caption>
            <p>Welding & Fabrication</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      </Row>
    </Container> 
  );
}

export default CarouselMod;
