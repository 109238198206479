//import React from "react";
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../assets/MasterCraftBodyworks.png';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import Toast from 'react-bootstrap/Toast';

class MyForm extends Component {

  constructor(props) {
    super(props);
    this.showInfo = true;
    this.state = {
      name: '',
      email: '',
      idea: '',
      showInfo: false,
      verified: false,
      reCaptchaResponse: '',
      submitSuccess: false,
      show: false
    };
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (this.state.name.length > 1 && this.state.email.length > 1 && this.state.idea.length > 1) {
        this.setState({
          showInfo: true
        });
      }

  }

  onChange = (result) => {   
    this.setState({
          verified: true,
          reCaptchaResponse: result
        });
    };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, idea, verified } = this.state;
    // Do something with this.state.inputValue
    //console.log('Submitted:', { name, email, idea, verified });


    if (this.state.verified) {

      fetch('https://ezkvg1uhhd.execute-api.eu-west-2.amazonaws.com/default/masterCraftContactForm', {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(this.state)
          }).then(function(response) {
            //console.log(response);
          }).then(HomeContactForm => {
            this.setState({ show: true, submitSuccess: true, showInfo: false,  name: '', email: '', idea: '',});
          });

          e.preventDefault();


    } else {
      e.preventDefault();
      alert ("Please let us know that you're not a robot!");
    }
  }

render() {
      return (
        <Container className="mt-5 pt-5 mb-5 pb-4">   
        <Row>
        <Col >
        <h1 className="servicesTitle mb-5">Contact us</h1>
        </Col>
        </Row>
          <Row className="p-2">
            <Col className="contactCopy paddingRight" style={{ textAlign: 'right', paddingRight: '30px'}} sm={6}> 
            <p><strong className="boldText">Phone:</strong> 07961 916558</p>
            </Col>
             <Col className="contactCopy paddingLeft" style={{ textAlign: 'left', paddingLeft: '30px'}} sm={6}> 
            <p><strong className="boldText">Email:</strong> <a href="mailto:info@mastercraftbodyworks.co.uk">info@mastercraftbodyworks.co.uk</a></p>
            </Col>
            </Row>
            <Row>
             <Col className="contactCopy pb-2" style={{ textAlign: 'center'}}> 
            <p><strong className="boldText">Address: </strong>
            Unit 8,
            Enstone Airfield North,
            Banbury Road,
            Enstone,
            Chipping Norton,
            OX7&nbsp;4NS</p>

        </Col>
     
          </Row>
      <Row className="g-0 p-2">
      <Col className="p-2" sm={4} style={{textAlign: 'left'}}>

     <Form className="formStyle" onSubmit={this.handleSubmit}>
       <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Full name</Form.Label>
            <Form.Control required type="text" placeholder="E.g. John Smith" name="name" onChange={this.handleInputChange} value={this.state.name} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Email address</Form.Label>
            <Form.Control required type="email" placeholder="E.g. name@example.com" name="email" onChange={this.handleInputChange} value={this.state.email} />
          </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" >
            <Form.Label>Your message</Form.Label>
            <Form.Control required as="textarea" placeholder="Type your message here..."  rows={6} name="idea" onChange={this.handleInputChange} value={this.state.idea} />
          </Form.Group>

           <div className="form-row mt-3" style={{ display: this.state.showInfo ? "block" : "none", padding: "0 0 10px 0" }}>
              <div className="col">
              <ReCAPTCHA
              sitekey="6Ld8AxYoAAAAAHR_XBxc7R7NKVFscPg1VtuJuKVZ"
              theme="light"                       
              onChange={this.onChange}                      
            />
                </div> 
                </div>

     <button type="submit" className="formSubmit">Submit message</button>
          </Form>

        <Toast style={{ marginTop: '30px'}} onClose={() => this.setState({ show: false }) } show={this.state.show} delay={2500} autohide>
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>Your message has been sent. We will be in touch shortly.</Toast.Body>
        </Toast>

      </Col>
      <Col className="googleMap p-4" sm={8}>
    <iframe className="googleMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2460.067934080136!2d-1.4401426381177276!3d51.932714413967936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876d72cebfdc145%3A0x1e3e7465098674c9!2sMASTERCRAFT%20BODYWORKS%20LTD!5e0!3m2!1sen!2suk!4v1692048062564!5m2!1sen!2suk" style={{ border: '0' }} loading="lazy"></iframe>
    </Col>

      </Row>


        </Container>  
  );
}
}

export default MyForm;