import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import video from '../assets/heroVid_1.mp4';

function VideoBg() {
  return (
    <Container fluid className="p-0"> 
      <Row className="g-0">
        <Col>
          <video className="bg-black" src={video} type='video/mp4' width="100%" autoPlay loop muted playsInline/>
        </Col>
      </Row>
    </Container>  
  );
}

export default VideoBg;