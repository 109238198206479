import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <Container fluid>
      <Row>
        <Col>
         <p><a href="https://www.instagram.com/mastercraftbw" target="_blank" style={{ textDecoration: 'none', color: '#ffffff' }}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png" width="20px" height="auto" style={{ display: 'inline'}} />  Follow us on Instagram</a></p>
          <p>&copy; {currentYear} MasterCraft Bodyworks</p>
        </Col>
      </Row>
    </Container>  
  );
}

export default Footer;