import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // You can create a CSS file for styling
import VideoBg from './components/VideoBg';
import MainCopy from './components/mainCopy';
import ContactUs from './components/contactUs';
import Header from './components/Header';
import CarouselMod from './components/Carousel';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <header className="banner">
        {/* Place your logo or banner content here */}
        <Header />
      </header>
      <section className="video-section">
        <VideoBg />
      </section>
      <section id="copySection" className="copy-section">
        <MainCopy />
      </section>
      <section id="services" className="carousel-section">
        <CarouselMod />
      </section>
      <section id="contactUs" className="contactUs-section">
        <ContactUs />
      </section>
      <section className="footer">
        <Footer />
      </section>
    </div>
  );
}

export default App;
