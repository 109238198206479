import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../assets/MasterCraftBodyworks.png';


function mainCopy() {
  return (
    <Container className="pt-3 pb-3">
     <Row className="g-0 p-2">
        <Col className="pb-4 removeImagePadding">
          <img src={image} className="bodyCopyImage" alt="Mastercraft Bodyworks" style={{ width: '550px' }} />     
        </Col>
      </Row>
      <Row className="g-0 p-2">
<Col className="bodyCopyContact p-2">    
  <h1 className="boldText">The experts in professional paint and bodywork</h1>
</Col>
</Row>
      <Row className="g-0 p-2">
        <Col className="bodyCopy p-2">   
<p><strong className="boldText">MasterCraft Bodyworks</strong> specialise in paint and body repair work, and have extensive experience across a diverse range of projects, including: collision repair and insurance jobs, high-end classic vehicle restoration, minor dent and scratch repair, welding, vehicle paint correction and ceramic coating.</p>
<p>We can also work on a variety of vehicles including cars, off-road vehicles, motorcycles and more. If it will fit in our booth, we can paint it!</p>
<p>Each project is approached with meticulous attention to detail and an unwavering commitment to quality.</p>
 </Col>
 </Row>
<Row className="g-0 pb-2">
<Col className="bodyCopyContact pb-2">    
  <button className="contactButton" onClick={e => {
              let hero = document.getElementById("contactUs");
              e.preventDefault();
              hero && hero.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }}>Contact us today for a quotation <font style={{fontSize: "15px", lineHeight: "0px"}}>&#x25B6;</font></button>
</Col>
</Row> 
 <Row className="g-0 p-2 noMobile">
 <Col className="bodyCopy p-2 mt-5">   
<strong className="boldText"><h3>What Sets MasterCraft Bodyworks Apart</h3></strong>
</Col>
</Row>
<Row className="noMobile">
<Col sm={6} className="bodyCopy p-2">
<strong className="boldText">Expertise:</strong> Our unmatched skill and passion drive us to deliver results that exceed expectations.
</Col>
<Col sm={6} className="bodyCopy p-2">
<strong className="boldText">Precision:</strong> Our meticulous approach ensures that every curve, line, and contour is restored to perfection.
</Col>
</Row>
<Row className="mb-5 noMobile">
<Col sm={6} className="bodyCopy p-2">
<strong className="boldText">Innovation:</strong> We embrace cutting-edge techniques and technology to deliver modern solutions that stand the test of time.
</Col>
<Col sm={6} className="bodyCopy p-2">
<strong className="boldText">Client-Centric:</strong> Your satisfaction is our ultimate goal. We collaborate closely with you and tailor our services to your needs.
</Col>
        
      </Row>

    </Container>  
  );
}

export default mainCopy;